.container {
    gap: 115px;
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 115px 50px;
    font-family: "Lexend", sans-serif;
}

.first_part {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    gap: 8px;
    height: calc(100vh - 200px);
    max-width: 280px;
    overflow: auto;
}

.second_part {
    flex: 4;
    width: 100%;
    max-width: 997px;
}

.first_container {
    min-width: 260px;
    min-height: 59px;
    border-radius: 10px;
    background: rgb(99, 102, 241);
    border: 1px solid rgb(93, 95, 239);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.second_title_content {
    display: flex;
    gap: 20px;
}

.second_input span {
    display: block;
    margin-bottom: 4px;
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 500;
}

.second_input span {
    display: block;
    margin-bottom: 4px;
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 500;
}

.second_input input {
    width: 100%;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 16px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
}

.second_input textarea {
    width: 100%;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 16px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
}

.rates_container {
    margin-top: 41px;
}

.rates_button_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
    margin: 0;
}

.rates_button {
    border: none !important;
    width: 212px !important;
    height: 50px !important;
    border-radius: 12px !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08) !important;
    background: rgb(99, 102, 241) !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: rgb(255, 255, 255) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.buttons_container {
    position: absolute;
    top: 104px;
    right: 50px;
    display: flex;
    gap: 5px;
}

.buttons_container_button {
    color: rgb(99, 102, 241);
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    background: rgb(240, 240, 253);
    padding: 13px 21px 13px 21px;
    border: none;
    cursor: pointer;
    max-height: 50px;
}



.background_container {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    padding: 50px 0;
    overflow-y: auto;
    z-index: 3;
}

.main_container {
    padding: 24px 34px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 1024px;
    height: 550px;
    box-sizing: border-box;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 24px;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
    font-family: "Lexend", sans-serif;
}

.center_elem {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_con {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main_con_header {
    margin: 10px 0;
}

.main_con_content {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin: 10px;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.main_con button {
    width: 180px;
    height: 40px;
    margin: 0 auto;
    background-color: blueviolet;
    color: white;
    border-radius: 10px;
    border: none;
}