.container {
    width: 356px;
}

.content_container {
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid rgb(144, 144, 144);
    border-radius: 8px;
    background: rgb(255, 255, 255);
    margin-top: 5px;
    overflow: hidden;
}

.content_title {
    min-height: 20px;
    display: flex;
    padding: 8px 14px;
    justify-content: space-between;
}

.title_names {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.title_names_placeholder {
    color: rgb(153, 154, 172);
    font-size: 14px;
    margin-top: 2px;
    font-weight: 400;
}

.title_names_chosen {
    padding: 4px 8px 4px 6px;
    border: 1px solid rgb(159, 179, 246);
    border-radius: 12px;
    background: rgb(223, 241, 252);
    color: rgb(77, 105, 199);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 2px;
}

.content {
    gap: 13px;
    margin: 20px 14px;
    display: flex;
    flex-direction: column;
    max-height: 228px;
    overflow: auto;
}

.content_elem {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgb(26, 33, 43);
    font-size: 16px;
    font-weight: 400;
}