.disable {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 8px;
}

.working {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: rgb(92, 23, 229);
    display: flex;
    align-items: center;
    justify-content: center;
}