.container {
    width: 100%;
    gap: 16px;
    max-width: 1340px;
    display: flex;
    flex-direction: column;
    font-family: "Lexend", sans-serif;
}

.content_container {
    width: 100%;
    padding: 10px 20px;
    border-radius: 20px;
}

.content_title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 31px;
}

.content_input_container {
    width: 100%;
    text-wrap: nowrap;
    display: flex;
    justify-content: space-between;
}

.content_input {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.content_input span {
    font-size: 16px;
    font-weight: 500;
}

.content_input input {
    height: 36px;
    margin-top: 12px;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 10px;
    background: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}