.main_container {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
}

.container {
    width: 459px;
    height: 547px;
    border-radius: 8px;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.08);
    background: rgb(255, 255, 255);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 15px 20px;
    color: rgb(17, 25, 39);
    font-weight: 500;
}

.header img {
    cursor: pointer;
}

.input {
    width: 419px;
    height: 39px;
    padding: 7px 19px 7px 19px;
    border: 1px solid rgb(157, 157, 157);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    margin: 0 20px 20px 20px;
    font-size: 14px;
    font-weight: 600;
}

.container_title {
    margin: 0 0 5px 25px;
    color: rgb(157, 157, 157);
    font-size: 16px;
    font-weight: 400;
}

.container_jobtitle {
    margin: 0 20px;
}

.bottom_container {
    margin-top: auto;
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 0 20px;
}

.first_button {
    height: 39px;
    padding: 7px 20px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(99, 102, 241);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    color: rgb(99, 102, 241);
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
}

.second_button {
    height: 39px;
    padding: 7px 20px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(99, 102, 241);
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    border: none;
}