.background_container {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    padding: 50px 0;
    overflow-y: auto;
    z-index: 5;
}

.main_container {
    padding: 24px 34px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    width: 1024px;
    height: min-content;
    box-sizing: border-box;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 24px;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
    font-family: "Lexend", sans-serif;
}

.first_title_container {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}

.second_title_content {
    display: flex;
    gap: 20px;
}

.second_input span {
    display: block;
    margin-bottom: 4px;
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 500;
}

.second_input span {
    display: block;
    margin-bottom: 4px;
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 500;
}

.second_input input {
    width: 100%;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 16px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
}

.second_input textarea {
    width: 100%;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 16px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
}

.rates_button {
    border: none;
    width: 212px;
    height: 50px;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(99, 102, 241);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    margin: 40px auto;
}

.error {
    display: flex;
    justify-content: center;
    color: red;
    margin: 0 auto;
}