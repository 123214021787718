.main_header {
    display: flex;
    align-items: center;
    max-width: 1330px;
    margin: 0px auto 29px;
}

.days_container {
    display: flex;
    margin-left: 269px;
    height: 100%;
    position: absolute;
    top: 90px;
}

.day_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 117px;
    height: 40px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(99, 102, 241);
    border-right: 1px solid rgb(99, 102, 241);
    border-top: 1px solid rgb(99, 102, 241);
    color: rgb(113, 113, 122);
    font-size: 14px;
    font-weight: 500;
}