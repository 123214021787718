.main_container {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
}

.container {
    width: 514px;
    border-radius: 8px;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.08);
    background: rgb(255, 255, 255);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 15px 20px;
    color: rgb(17, 25, 39);
    font-weight: 500;
}

.header img {
    cursor: pointer;
}

.status {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.statuses_container {
    display: flex;
    gap: 8px;
    padding: 0 20px;
}

.statuses_add_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.statuses_container_inactive .status_container {
    border: 1px solid rgb(192, 192, 192);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    color: rgb(192, 192, 192);
}

.status_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 37px;
    cursor: pointer;
}

.status_active {
    border: 1px solid rgb(99, 102, 241);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(99, 102, 241);
    color: rgb(255, 255, 255);
}

.status_disable {
    color: rgb(99, 102, 241);
    border: 1px solid rgb(99, 102, 241);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
}

.first_time_container, .second_time_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 53px;
    padding: 6px 16px 6px 16px;
    border-radius: 10px;
    background: rgb(244, 244, 245);
    font-size: 12px;
    font-weight: 400;
}

.first_time_container span, .second_time_container span {
    color: #6366F1;
}

.first_time_container span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
}

.first_time_container input, .second_time_container input {
    width: 47px;
    margin-top: 0px;
    box-sizing: border-box;
    border: none;
    border-radius: 0px;
    background: transparent;
    text-align: center;
    margin-right: 0px;
    display: flex;
    justify-content: start;
}

.first_time_container {
    display: flex;
    flex-direction: column;
}

.first_time_container div {
    display: flex;
}

.second_time_container {
    gap: 2px;
    margin-top: 6px;
}

.additional_statuses_container {
    display: flex;
    align-items: center;
    margin: 28px 0 57px 40px;
    gap: 10px;
}

.additional_status_container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.bottom_container {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    height: 71px;
    padding: 0 20px;
}

.first_button {
    height: 39px;
    padding: 7px 20px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(99, 102, 241);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    color: rgb(99, 102, 241);
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
}

.second_button {
    height: 39px;
    padding: 7px 20px 7px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(99, 102, 241);
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    border: none;
}