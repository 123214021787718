.main_container {
    padding: 24px 50px 73px 50px;
}

.main_container hr {
    height: 1px;
    background-color: #F2F4F7;
    border: none;
    margin: 13px 0;
}

.main_header {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 1770px;
    margin: 0px auto;
}

.main_header_filter {
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(236, 245, 255);
    position: relative;
}

.modal_filter_container {
    z-index: 3;
    width: 254px;
    box-sizing: border-box;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
    background: rgb(255, 255, 255);
    padding: 11px 20px 11px 20px;
    position: absolute;
    margin: 470px 200px 0 0;
    cursor: auto;
    font-family: "Lexend", sans-serif;
}

.modal_filter_container hr {
    width: 100%;
    height: 0px;
    box-sizing: border-box;
    border-bottom: 1.111109972000122px solid rgb(242, 244, 247);
}

.modal_filter_filt {
    cursor: pointer;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(153, 154, 172);
    font-size: 16px;
    font-weight: 500;
}

.modal_filter_statuses {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.modal_filter_input {
    width: 100%;
    height: 39px;
    padding: 7px 19px 7px 19px;
    margin-top: 5px;
    box-sizing: border-box;
    border: 1px solid rgb(153, 154, 172);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
}

.modal_status_container {
    cursor: pointer;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(153, 154, 172);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    color: rgb(153, 154, 172);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
}