.main_container {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
}

.container {
    width: 240px;
    border-radius: 8px;
    box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.08);
    background: rgb(255, 255, 255);
}

.header {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 30px;
}

.header img {
    cursor: pointer;
}

.date_container {
    width: 180px;
    margin: 8px auto 20px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
}

.date_container span {
    color: rgb(99, 102, 241);
}

.container button {
    width: 165px;
    height: 39px;
    border: 1px solid rgb(99, 102, 241);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    color: rgb(99, 102, 241);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 20px auto 35px;
}