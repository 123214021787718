header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background: rgb(247, 250, 254);
    padding: 0 50px;
    position: fixed;
    top: 0;
    z-index: 3;
}

.header_div {
    width: 100%;
    height: 80px;
}

header img {
    cursor: pointer;
}

.icons_and_avatar {
    display: flex;
    gap: 36px;
}

.icons_and_avatar ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.icons_and_avatar a {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 38px;
    margin-bottom: 7px;
}

.icons_and_avatar li {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.icons_and_avatar svg {
    margin-right: 10px;
}

.avatar {
    min-width: 48px;
    height: 48px;
    border-radius: 24px;
}

.svgicon {
    width: 24px;
    height: 24px;
}

@media (max-width: 856px) {
    .icon_main {
        display: none;
    }
}

@media (max-width: 520px) {
    .icons_and_avatar a svg {
        display: none;
    }
}