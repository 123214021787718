.elem_jobtitles {
    width: 314px;
    display: flex;
    align-items: center;
    color: rgb(112, 118, 131);
    font-size: 13px;
    font-weight: 400;
    position: relative;
}

.elem_jobtitles img {
    cursor: pointer;
    margin-left: 30px;
}

.dop_jobtitles {
    color: red;
    margin-left: 5px;
}

.list_jobtitles {
    position: absolute;
    top: 35px;
    border: 1px solid;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}