.settingsHeader ul {
    gap: 35px;
    display: flex;
    list-style: none;
    padding: 0 50px;
}

.settingsHeader li {
    height: 48px;
    display: flex;
    align-items: center;
}

.buttons_container {
    position: absolute;
    top: 104px;
    right: 50px;
    display: flex;
    gap: 5px;
}

.buttons_container button {
    color: rgb(99, 102, 241);
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    background: rgb(240, 240, 253);
    padding: 13px 21px 13px 21px;
    border: none;
    cursor: pointer;
}