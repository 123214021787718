.main_container {
    margin: 0px 50px 73px 50px;
    position: relative;
}

.choose_container {
    width: 100%;
    height: 78px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-bottom: 10px;
}