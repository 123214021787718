.background_container {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    overflow-y: auto;
    z-index: 3;
}

.main_container {
    padding: 24px 34px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 1024px;
    height: 550px;
    box-sizing: border-box;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 24px;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
    font-family: "Lexend", sans-serif;
}

.modal_container {
    padding: 14px 24px;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    max-width: 324px;
    height: 200px;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 24px;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.16);
    font-weight: 500;
    text-align: center;
}

.modal_container span {
    font-weight: 700;
}

.modal_container .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 116px;
    height: 42px;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: green;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.modal_container .save {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 116px;
    height: 42px;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(204, 0, 0);
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.fired {
    display: flex;
    align-items: center;
    gap: 8px;
}

.main_header {
    color: rgb(26, 33, 43);
    font-size: 22px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.main_header img {
    cursor: pointer;
    height: 16px;
}

.main_content {
    padding-top: 29px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-direction: column;
}

.content_header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.header_name {
    color: rgb(81, 82, 85);
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.header_name img, .header_name_img  {
    width: 90px;
    height: 90px;
    border-radius: 9999px;
    background-color: #6366F1;
    margin-right: 38px;
}

.header_name input {
    width: 360px;
    height: 39px;
    padding: 7px 19px 7px 19px;
    box-sizing: border-box;
    border: 1px solid rgb(153, 154, 172);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    font-size: 14px;
    font-weight: 400;
}

.header_jobtitle_container span {
    color: rgb(41, 41, 41);
    font-size: 16px;
    font-weight: 400;
}

.rates_title {
    color: rgb(26, 33, 43);
    font-size: 22px;
    font-weight: 600;
}

.rates_second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin-top: 20px;
}

.rates_second_name {
    display: flex;
    gap: 30px;
}

.rates_second_name span {
    color: rgb(165, 166, 246);
    font-size: 16px;
    font-weight: 500;
}

.rates_second_checkbox {
    display: flex;
    align-items: center;
    color: rgb(26, 33, 43);
    font-size: 16px;
    font-weight: 400;
}

.rates_second_checkbox input {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 8px;
    background: rgb(255, 255, 255);
    margin: 0 8px;
}

.buttons_container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 68px);
    bottom: 24px;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 32px;
}

.delete {
    color: rgb(39, 49, 63);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.save {
    width: 116px;
    height: 39px;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(177, 177, 177);
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
}

.title {
    font-size: 14px;
    font-weight: 400;
}

@media (max-width: 1015px) {
    .content_header {
        margin-bottom: 50px;
    }
}