/*variables*/
:root {
    --white: #fff;
    --main: #eaedf0;
    --accent: #0041ff;
    --accent-2: #ebedf0;
}

.date_calendar_container {
    display: flex;
    flex-direction: column;
}

.header_arrow_icon {
    cursor: pointer;
}

.header_arrow_icon:hover {
    color: #6366F1;
}

.header_arrow_icon:last-child {
    transform: rotate(180deg);
}

.date_container {
    width: 227px;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid rgb(179, 203, 226);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}

.header_arrow_container {
    height: 24px;
}

.date_container span {
    margin-right: 5px;
    font-size: 18px;
    font-weight: 500;
}

.date_container span:first-child {
    margin-right: 5px;
    color: #6366F1;
    font-weight: 400;
}

.container_calendar {
    display: none;
    width: 328px;
    height: 392px;
    padding: 0 24px;
    box-sizing: border-box;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 24px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
    background: rgb(255, 255, 255);
}

.calendar_content {
    margin-top: 46px;
    padding-top: 35px;
    z-index: 10;
    position: absolute;
}

/* hover */
.date_calendar_container .container_calendar {
    display: inline-block;
}

/* hover */
.container_calendar {
    display: inline-block;
}

.header_calendar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
}

.header_display {
    display: flex;
    align-items: center;
}

.display {
    color: #27313F;
    margin: 5px;
    font-size: 1.2rem;
    word-spacing: 0.5rem;
    display: flex;
    gap: 24px;
}

.display span {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.display span:last-child {
    font-weight: 700;
}

.display img {
    height: 12px;
    margin-left: 8px;
}

pre {
    padding: 10px;
    margin: 0;
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--accent);
}

.week {
    padding-top: 16px;
}

.days,
.week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: auto;
    justify-content: space-between;
}
.week div,
.days div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}
.days div:hover {
    background: var(--accent-2);
    color: rgb(25, 25, 201);
    cursor: pointer;
}
.week div {
    opacity: 0.5;
}
.current_date {
    background-color: var(--accent);
    color: var(--white);
}
.display_selected {
    margin-bottom: 10px;
    padding: 20px 20px;
    text-align: center;
}

.month_container, .year_container {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 103px;
    height: 265px;
    box-sizing: border-box;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
    background: rgb(255, 255, 255);
    z-index: 11;
    gap: 12px;
    padding: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: "Lexend", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #27313F;
    margin: 140px 60px;
}

.year_container {
    margin: 140px 165px;
}

.month_container span, .year_container span {
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.month_container::-webkit-scrollbar, .year_container::-webkit-scrollbar {
    width: 5px;     
}
  
.month_container::-webkit-scrollbar-track, .year_container:-webkit-scrollbar-track {
    background: #EEF2F6;
}
  
.month_container::-webkit-scrollbar-thumb, .year_container::-webkit-scrollbar-thumb {
    background-color: #AABACB;
    border-radius: 20px;
}

.date_container_input {
    width: 360px;
    height: 39px;
    padding: 7px 19px 7px 19px;
    box-sizing: border-box;
    border: 1px solid rgb(153, 154, 172);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    font-size: 14px;
    font-weight: 400;
}