.table_container {
    max-width: 1340px;
    width: calc(100vw - 50px);
    height: 228px;
    border-radius: 20px;
    box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03),0px 5px 22px 0px rgba(0, 0, 0, 0.04);
    background: rgb(255, 255, 255);
    margin: 0px auto;
}

.table_container ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 31px 0 4px 0;
    font-size: 14px;
    font-weight: 500;
    margin: 0 22px;
    color: #71717A;
}

.sticks_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 25px 0 30px;
}

.stick {
    width: 2px;
    height: 6px;
    background: rgb(220, 214, 245);
    border-radius: 24px;
}

.empty_lines_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 4px;
}

.empty_line_container {
    position: relative;
    overflow: hidden;
    margin: 0 23px;
    height: 6px;
    background-color: rgb(242, 249, 255);
    border-radius: 20px;
}

.active_line_container {
    transition: all 0.5s;
    position: absolute;
    height: 6px;
    border-radius: 20px;
    background-color: rgb(223, 231, 9);
}

.colors_container {
    color: rgb(113, 113, 122);
    font-size: 14px;
    font-weight: 500;
    margin: 44px 0 0 24px;
    display: flex;
    gap: 21px;
}

.color_container {
    display: flex;
    align-items: center;
}

.color {
    width: 20px;
    height: 20px;
    border-radius: 9999px;
    margin-right: 9px;
}