.container {
    margin-top: 38px;
    font-family: "Lexend", sans-serif;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}
input:focus {
    outline: none;
    border: none;
  }
.times_container {
    margin: 53px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 1340px;
}

.time_container {
    width: 660px;
    height: 136px;
    border-radius: 20px;
    background: rgb(242, 249, 255);
    padding: 32px 20px;
    display: flex;
}

.time_container_title {
    font-size: 16px;
    font-weight: 600;
    color: #1A212B;
    margin-right: 47px;
}

.time_container_input {
    display: flex;
    flex-direction: column;
}

.time_container_input span {
    font-weight: 500;
    font-size: 16px;
    color: #27313F;
}

.time_container_input input {
    width: 245px;
    height: 36px;
    margin-top: 11px;
    box-sizing: border-box;
    border: none;
    border-radius: 10px;
    background: transparent;
    margin-right: 20px;
}

.time_input {
    width: 245px;
    height: 36px;
    margin-top: 12px;
    box-sizing: border-box;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 10px;
    background: rgb(255, 255, 255);
    text-align: center;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.time_input input {
    width: 47px;
    height: 30px;
    margin-top: 0px;
    box-sizing: border-box;
    border: none;
    border-radius: 0px;
    background: transparent;
    text-align: center;
    margin-right: 0px;
    display: flex;
    justify-content: start;
}

.error {
    color: red;
}

.button_container {
    margin: 84px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}

.container button {
    width: 212px;
    height: 50px;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(99, 102, 241);
    color: rgb(255, 255, 255);
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
}