.employees_container {
    width: 100vw;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1360px;
    padding-top: 31px;
    margin: 0px auto;
    font-family: "Poppins", sans-serif;
}

.header_container {
    display: flex;
    justify-content: space-between;
}

.filter_container {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: rgb(106, 112, 126);
}

.current_filter {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
    color: rgb(99, 102, 241);
}

.filter_container_img {
    width: 7px;
    height: 4px;
    margin-left: 6px;
}

.filter_content {
    position: absolute;
    box-sizing: border-box;
    width: 254px;
    height: 356px;
    padding: 17px 20px;
    box-sizing: border-box;
    border: 1px solid rgb(236, 239, 244);
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.16);
    background: rgb(255, 255, 255);
    margin-top: 400px;
    font-family: "Lexend", sans-serif;
}

.filter_content_header {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;
}

.filter_content hr {
    width: 100%;
    height: 0px;
    border: none;
    margin: 0;
    border-bottom: 1.111109972000122px solid rgb(242, 244, 247);
}

.filter_content_other {
    color: rgb(99, 102, 241);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;
}

.filter_content_other {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: rgb(153, 154, 172);
    font-size: 16px;
    font-weight: 500;
}

.filter_content_other span {
    cursor: pointer;
}

.header_container button {
    width: 193px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(99, 102, 241);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    color: rgb(99, 102, 241);
    font-size: 14px;
    font-weight: 600;
    background: transparent;
    cursor: pointer;
}

.main_container {
    width: 100%;
}

.main_header {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding: 22px 16px 22px 24px;
}

.main_header_select {
    display: flex;
    align-items: center;
    color: rgb(51, 77, 110);
    font-size: 13px;
    font-weight: 500;
}

.main_header_select input {
    margin-right: 24px;
}

.main_header_delete {
    display: flex;
    align-items: center;
    color: rgb(112, 118, 131);
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

.main_header_delete img {
    height: 16px;
    margin-right: 12px;
}

.main_content_container {
    margin-top: 14px;
}

.elem_container {
    display: flex;
    gap: 20px;
    padding: 22px 16px 22px 24px;
    border-bottom: 1px solid rgb(235, 239, 242);
}

.input_all_header {
    display: flex;
}

.input_all {
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: 9px;
    font-weight: 700;
    min-width: 16px;
    height: 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
}

.elem_name {
    width: 236px;
    display: flex; 
    align-items: center;
    color: rgb(50, 60, 71);
    font-size: 15px;
    font-weight: 500;
}

.elem_name img {
    width: 24px;
    height: 24px;
    background-color: rgb(99, 102, 241);
    border-radius: 9999px;
    margin-left: 24px;
    margin-right: 12px;
}

.elem_email {
    display: flex;
    align-items: center;
    width: 318px;
    color: rgb(112, 118, 131);
    font-size: 13px;
    font-weight: 400;
}

.elem_jobtitles {
    width: 314px;
    display: flex;
    align-items: center;
}

.elem_statuses {
    width: 255px;
    display: flex;
    align-items: center;
}

.is_fired {
    width: 79px;
    height: 24px;
    padding: 0 8px 0 4px;
    border: 1px solid rgb(246, 159, 159);
    border-radius: 12px;
    background: rgb(252, 223, 223);
    color: rgb(82, 5, 5);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.is_fired img {
    cursor: pointer;
}

.elem_change {
    color: rgb(112, 118, 131);
    font-size: 13px;
    font-weight: 400;
    gap: 35px;
    display: flex;
    align-items: center;
}

.elem_change span {
    cursor: pointer;
}

.jobtitle_choose_head {
    color: rgb(39, 49, 63);
    font-family: "Lexend", sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 8px 0 5px 0;
}

.input_filter {
    border: 1px solid rgb(153, 154, 172);
    border-radius: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    width: 214px;
    height: 39px;
    padding: 7px 19px 7px 19px;
    color: rgb(153, 154, 172);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
}