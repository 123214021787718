.password_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 129px;
    gap: 40px;
}

.input_container {
    display: flex;
    flex-direction: column;
}

.input_container img {
    width: 24px;
    height: 24px;
    position: absolute;
    margin-top: 61px;
    margin-left: 393px;
}

.input_container input {
    width: 434px;
    height: 64px;
    padding: 12px 16px 12px 16px;
    box-sizing: border-box;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 6px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
}

.password_container span {
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
}

.password_container button {
    width: 434px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: rgb(99, 102, 241);
    border: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    margin-top: 40px;
}

.password_container span {
    color: rgb(105, 111, 121);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}

.error_container {
    color: red;
    margin: 10px auto;
    display: flex;
    justify-content: center;
}