.container {
    padding: 0 20px;
}

.info_container {
    width: 100%;
    max-width: 1340px;
    padding: 18px 14px 49px 34px;
    border-radius: 20px;
    margin: 25px auto;
    box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03),0px 5px 22px 0px rgba(0, 0, 0, 0.04);
    background: rgb(255, 255, 255);
}

.info_container_title {
    display: flex;
    justify-content: space-between;
}

.title_info_container {
    display: flex;
}

.title_info_img {
    width: 110px;
    height: 110px;
    border: 1px dashed rgb(210, 214, 219);
    border-radius: 99px;
}

.title_info_img img {
    width: 100px;
    height: 100px;
    border-radius: 99px;
    background-color: #6366F1;
    margin: 5px auto;
}

.info_inputs_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: "Lexend", sans-serif;
}

.info_input_container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.info_input {
    flex: 1;
}

.info_input_span {
    display: block;
    height: 15px;
    margin-bottom: 4px;
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 500;
}

.info_input input {
    width: 100%;
    height: 48px;
    padding: 12px 16px 12px 16px;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 16px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
}

.daytable_container {
    margin: 25px 0 48px 0;
}

.rate_container {
    width: 100%;
    max-width: 1340px;
    margin: 46px auto;
    font-family: "Lexend", sans-serif;
}

.rate_container_title {
    color: rgb(26, 33, 43);
    font-size: 22px;
    font-weight: 600;
}

.rate_individual_container {
    width: 100%;
    margin: 28px 0;
    display: flex;
    justify-content: space-between;
}

.rate_individual {
    color: rgb(26, 33, 43);
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.rate_individual input {
    width: 24px;
    height: 24px;
    color: rgb(26, 33, 43);
    font-size: 16px;
    font-weight: 400;
    margin-right: 8px;
}

.rate_individual input[type=checkbox] {
    accent-color: rgb(99, 102, 241);
}

.buttons_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 46px 0;
    gap: 60px;
}

.buttons_container_button {
    height: 50px;
    color: rgb(39, 49, 63);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.buttons_container button:last-child {
    width: 212px;
    height: 50px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
    background: rgb(99, 102, 241);
    border: none;
    cursor: pointer;
}