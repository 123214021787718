.table_container {
    position: relative;
    max-width: 1770px;
    margin: 0px auto 16px;
}

.top_part_container {
    display: flex;
    margin-bottom: 13px;
}

.job_title_container {
    display: flex;
    position: sticky;
    background: white;
    z-index: 2;
    cursor: pointer;
    left: 0;
}

.today {
    background-color: rgb(141 209 230) !important;
    border-radius: 0px !important;
}

.weekend {
    background-color: #dddddd !important;
}

.next_day_weekend {
    border-radius: 0px !important;
}

.prev_day_weekend {
    border-radius: 0px !important;
}

.job_title_container span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #E8F0F9;
    font-size: 16px;
    word-break: break-all;
    font-weight: 500;
    padding: 5px 9px 5px 16px;
    padding-left: 16px;
    padding-right: 9px;
    min-height: 48px;
}

.table_plus {
    width: 48px;
    height: 48px;
    margin-left: 5px;
    border-radius: 10px;
    background: rgb(236, 245, 255);
    display: flex;
    align-items: center;
    justify-content: center;
}

.left_part {
    position: relative;
    display: flex;
    z-index: 2;
    height: 50px;
    border-bottom: 1px solid rgb(242, 244, 247);
}

.top_part_container_statistics {
    display: flex;
    margin-top: 4px;
}

.top_day_active {
    width: 46px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    color: rgb(99, 102, 241);
    border: 2px solid rgb(99, 102, 241);
}

.top_part_elem_statistics {
    width: 133px;
    height: 40px;
    border: 1px solid rgb(179, 203, 226);
    background: rgb(99, 102, 241);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
}

.name_container {
    max-width: 225px;
    min-width: 225px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 13px;
    box-shadow: 2px 0px 10px -5px rgba(0, 0, 0, 0.25);
    position: sticky;
    left: 0;
    background-color: white;
}

.name_container img {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: rgb(99, 102, 241);
}

.name_container span {
    color: rgb(17, 25, 39);
    font-size: 14px;
    font-weight: 500;
}

.top_part_list {
    display: flex;
    position: absolute;
    height: 100%;
    margin-left: 270px;
}

.top_list_item {
    width: 48px;
    height: calc(100% - 4px);
    display: flex;
    justify-content: center;
    color: #71717A;
    border-radius: 10px;
    margin-top: 4px;
}

/* Стили для .left_part */
.data_container:hover {
    border-radius: 8px 8px 10px 10px;
    background: rgb(248, 242, 255);
}

.data_container {
    display: flex;
    align-items: center;
    padding-left: 45px;
}

.bottom_part_elem {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #6366F1;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.bottom_part_elem span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.bottom_part_elem p {
    font-size: 11px;
    letter-spacing: -1.5px;
    color: rgb(237, 35, 24);
}

.botton_add {
    width: 225px;
    color: rgb(164, 185, 208);
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 11px;
    padding: 7px 11px;
    box-shadow: 2px 0px 10px -5px rgba(0, 0, 0, 0.25);
    background: white;
    position: sticky;
    left: 0;
    z-index: 2;
}

.botton_add svg {
    width: 10px;
}