.login_container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 50px;
}

.login_content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login_title {
    color: rgb(99, 102, 241);
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 14px;
}

.input_container {
    display: flex;
    flex-direction: column;
}

.show_password_icon {
    width: 24px;
    height: 24px;
    position: absolute;
    margin-top: 61px;
    margin-left: 393px;
}

.input_container input {
    width: 434px;
    height: 64px;
    padding: 12px 16px 12px 16px;
    box-sizing: border-box;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 6px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
}

.input_error {
    border: 2px solid rgb(241, 74, 88) !important;
}

.error_container {
    margin-top: 4px;
    display: flex;
    color: rgb(177, 0, 15);
    font-size: 12px;
    font-weight: 500;
}

.error_icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-left: 16px;
}

.login_content span {
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
}

.login_content button {
    width: 434px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: rgb(99, 102, 241);
    border: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
}

.login_content span {
    color: rgb(105, 111, 121);
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
}