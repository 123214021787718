.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input_title {
    display: block;
    color: rgb(114, 129, 151);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
}

.container input {
    width: 100%;
    border: 1px solid rgb(154, 168, 188);
    border-radius: 16px;
    background: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    padding: 12px 16px 12px 16px;
}

.container_first, .container_second {
    height: 92px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.input_container {
    width: 50%;
}